<div
	class="arcade-tile-wrapper"
	[style]="tileStyle"
	[ngClass]="{ transparent: transparent, 'optimized-tile': optimize }"
>
	<div
		class="arcade-tile"
		[style]="tileStyle"
		[ngClass]="{
			'hide-footer': hideFooter,
			'glow-border': glowBorder,
			'blur-content': blur
		}"
	>
		<div class="tile-content" #content [style]="contentStyle" draggable="false">
			<ng-content></ng-content>
		</div>
		<div class="tile-footer" *ngIf="!hideFooter" [style]="footerStyle">
			<span class="tile-footer-title" *ngIf="title">{{ title }}</span>
			<span class="tile-footer-actions">
				<!-- ng content -->
				<ng-content select="[arcade-tile-footer-actions]"></ng-content>
			</span>
		</div>
	</div>
</div>
