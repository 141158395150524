import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";

@Component({
	selector: "arcade-three-d",
	templateUrl: "./arcade-three-d.component.html",
	styleUrls: ["./arcade-three-d.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArcadeThreeDComponent implements AfterViewInit, OnChanges, OnDestroy {
	@ViewChild("wrapper") wrapperRef: ElementRef;
	modelViewer: ModelViewerElement;
	// modelviewer library atrributes
	@Input() alt = "Metaquarium 3D";
	@Input() autoRotate?: boolean = false;
	@Input() autoRotateDelay?: number = 0;
	@Input() autoRotateReversed?: boolean = false;
	@Input() autoRotateSpeed?: number;
	@Input() backgroundColor?: string;
	@Input() cameraControls = false;
	@Input() cameraOrbit?: string = "auto auto auto";
	@Input() cameraTarget?: string;
	@Input() colorGamut?: string;
	@Input() exposure?: number;
	@Input() fieldOfView?: string;
	@Input() iar?: boolean;
	@Input() lightIntensity?: number;
	@Input() minFieldOfView?: number;
	@Input() reveal?: string;
	@Input() shadowIntensity?: number;
	@Input() src: string;
	@Input() theme?: string;
	@Input() type?: string;
	@Input() upAxis?: string;
	@Input() poster?: string;
	@Input() posterAlt?: string;
	@Input() disableTap?: boolean;
	@Input() disableZoom?: boolean;
	@Input() disablePan?: boolean;
	@Input() interactionPrompt?: string;
	@Input() interactionPromptStyle?: string;
	@Input() autoPlay?: boolean;

	// custom
	@Input() size?: number;
	@Input() visible = true;
	@Input() glass = false;
	@Input() autoSize = false;
	@Input() scale?: string;
	@Input() name?: string;
	@Input() glassOnly = false;

	showModel = false;
	modelSrc: string;
	fallbackSrc = "assets/objects/bowl.glb";
	fallbackSize = 120;
	height = this.size;
	width = "100%";
	initialized = false;
	
	// Track event listeners for proper cleanup
	private eventListeners: Array<{target: any, event: string, handler: any}> = [];
	// Track animation frame for cleanup
	private animationFrameId: number | null = null;

	constructor(
		private ngZone: NgZone
	) {
		if (!this.visible) {
			this.visible = true;
		}
	}

	getModelSrc() {
		if (!this.src || this.src === "") {
			this.modelSrc = this.fallbackSrc;
		} else {
			this.modelSrc = this.src;
		}

		return this.modelSrc;
	}

	ngAfterViewInit() {
		this.ngZone.runOutsideAngular(() => {
			if (!this.size) {
				this.height = this.fallbackSize;
				this.width = "100%";
			} else {
				this.height = this.size;
			}

			this.loadModel();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initialized) {
			// Run outside Angular zone to prevent triggering change detection
			this.ngZone.runOutsideAngular(() => {
				this.updateModelViewer();
			});
		}
	}
	
	ngOnDestroy(): void {
		// Cancel any pending animation frames
		if (this.animationFrameId !== null) {
			cancelAnimationFrame(this.animationFrameId);
		}
		
		// Clean up event listeners
		this.eventListeners.forEach(({ target, event, handler }) => {
			target.removeEventListener(event, handler);
		});
		this.eventListeners = [];
		
		// Remove the model viewer from the DOM if it exists
		if (this.modelViewer && this.wrapperRef?.nativeElement) {
			try {
				this.wrapperRef.nativeElement.removeChild(this.modelViewer);
			} catch (e) {
				// Element might already be removed
			}
		}
	}

	loadModel() {
		this.createModelViewer();
	}

	updateModelViewer() {
		// Batch DOM operations to minimize layout thrashing
		if (!this.modelViewer) return;
		
		// Use requestAnimationFrame to batch updates
		this.animationFrameId = requestAnimationFrame(() => {
			if (!this.visible && !this.modelViewer.modelIsVisible) {
				this.dismissPoster();
			}

			// Check for animations and update autoplay in one pass
			const hasAnimations = this.modelViewer.getAnimations().length > 0;
			
			// Batch attribute updates
			this.setAttributes({
				src: this.getModelSrc(),
				alt: this.alt,
				...(hasAnimations && this.autoPlay ? { autoplay: "" } : {})
			});
		});
	}

	inspect(_event) {
		// Run model inspection outside Angular zone
		this.ngZone.runOutsideAngular(() => {
			const getAnimations = this.modelViewer.getAnimations();
			const maximumFieldOfView = this.modelViewer.getMaximumFieldOfView();
			const minimumFieldOfView = this.modelViewer.getMinimumFieldOfView();
			const getIdealAspect = this.modelViewer.getIdealAspect();
			const getCameraOrbit = this.modelViewer.getCameraOrbit();
			const getCameraTarget = this.modelViewer.getCameraTarget();
			const getCameraFieldOfView = this.modelViewer.getFieldOfView();
			const getDimensions = this.modelViewer.getDimensions();
			const getBoundingBoxCenter = this.modelViewer.getBoundingBoxCenter();
			
			// If you need to update the UI based on these values, re-enter the zone
			// this.ngZone.run(() => {
			//   // Update component properties or UI here if needed
			// });
		});
	}

	createModelViewer() {
		// Create a new Model Viewer element
		this.modelViewer = new ModelViewerElement();
		
		// Prepare all attributes in a single object to batch DOM changes
		const attributes: Record<string, string> = {
			src: this.getModelSrc(),
			alt: this.alt,
			bounds: "tight",
			"rotation-per-second": "240%",
			"environment-image": "neutral",
			"shadow-intensity": this.shadowIntensity?.toString() || "1",
			"camera-orbit": this.cameraOrbit || "auto auto auto",
			"camera-target": this.cameraTarget || "auto auto auto"
		};
		
		if (this.name) {
			attributes.name = this.name;
			attributes.id = this.name;
		}
		
		if (this.visible === false) {
			attributes.reveal = "manual";
		}
		
		if (this.autoRotate) {
			attributes["auto-rotate"] = "";
		}
		
		if (this.disableTap) {
			attributes["disable-tap"] = "";
		}
		
		if (this.disablePan) {
			attributes["disable-pan"] = "";
		}
		
		if (this.disableZoom) {
			attributes["disable-zoom"] = "";
		}
		
		if (this.interactionPrompt) {
			attributes["interaction-prompt"] = this.interactionPrompt;
		}
		
		if (this.interactionPromptStyle) {
			attributes["interaction-prompt-style"] = this.interactionPromptStyle;
		}
		
		if (this.cameraControls) {
			attributes["camera-controls"] = "";
		}
		
		if (this.poster) {
			attributes.poster = this.poster;
		}
		
		if (this.autoPlay) {
			attributes.autoplay = "";
		}
		
		// Add CSS class
		this.modelViewer.classList.add("arcade-model-3d");
		
		// Set dimensions
		if (!this.autoSize) {
			this.modelViewer.style.width = this.height + "px";
			this.modelViewer.style.height = this.height + "px";
		} else {
			this.modelViewer.style.height = this.size + "px";
			// Move DOM operations inside requestAnimationFrame for better performance
			this.animationFrameId = requestAnimationFrame(() => {
				const width = document.getElementById("loaderWrapper")?.offsetWidth;
				if (width) {
					this.modelViewer.style.width = width + "px";
				}
			});
		}
		
		if (this.scale) {
			this.modelViewer.scale = this.scale;
		}
		
		// Apply all attributes in batch
		this.setAttributes(attributes);
		
		// Handle model-viewer events outside Angular zone
		const addEventListenerOutsideZone = (target, event, handler) => {
			const boundHandler = (...args) => handler(...args);
			target.addEventListener(event, boundHandler);
			this.eventListeners.push({ target, event, handler: boundHandler });
		};
		
		// Add common model-viewer events
		addEventListenerOutsideZone(this.modelViewer, 'load', () => {
			// Only update UI-related properties inside the Angular zone
			this.ngZone.run(() => {
				this.initialized = true;
			});
		});
		
		addEventListenerOutsideZone(this.modelViewer, 'error', (event) => {
			console.error('Model viewer error:', event);
		});
		
		addEventListenerOutsideZone(this.modelViewer, 'model-visibility', () => {
			// Handle visibility changes if needed
		});
		
		// Add performance-critical events that should stay outside Angular zone
		addEventListenerOutsideZone(this.modelViewer, 'camera-change', () => {
			// Handle camera changes outside the zone
		});
		
		addEventListenerOutsideZone(this.modelViewer, 'progress', () => {
			// Handle loading progress outside the zone
		});
		
		// Append to DOM
		this.wrapperRef.nativeElement.appendChild(this.modelViewer);
	}
	
	// Helper method to set multiple attributes in batch
	private setAttributes(attributes: Record<string, string>) {
		Object.entries(attributes).forEach(([attr, value]) => {
			this.modelViewer.setAttribute(attr, value);
		});
	}

	public dismissPoster() {
		// Run outside Angular zone
		this.ngZone.runOutsideAngular(() => {
			this.modelViewer?.dismissPoster();
		});
	}
}
