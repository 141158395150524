import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { RouterModule } from "@angular/router";
import { ArcadeButtonComponent } from "../arcade-button/arcade-button.component";
import { ArcadeConsoleComponent } from "../arcade-console/arcade-console.component";
import { ArcadeDisplayComponent } from "../arcade-display/arcade-display.component";
import { ArcadeIndicatorComponent } from "../arcade-indicator/arcade-indicator.component";
import { ArcadeLogoComponent } from "../arcade-logo/arcade-logo.component";
import { ArcadeSwitchComponent } from "../arcade-switch/switch.component";
import { ArcadeThreeDComponent } from "../arcade-three-d/arcade-three-d.component";
import { ArcadeTileComponent } from "../arcade-tile/arcade-tile.component";
import { ArcadeTypeComponent } from "../arcade-type/arcade-type.component";
import { ArcadeService } from "../arcade.service";
import { ArcadeComponent } from "./arcade.component";

// Primitives required for the arcade components
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from "@angular/material/tooltip";
import { ArcadeKnobComponent } from "../arcade-knob/knob.component";
import { ArcadeToggleComponent } from '../arcade-toggle/toggle.component';

@NgModule({
	declarations: [
		ArcadeComponent,
		ArcadeDisplayComponent,
		ArcadeButtonComponent,
		ArcadeConsoleComponent,
		ArcadeTileComponent,
		ArcadeIndicatorComponent,
		ArcadeSwitchComponent,
		ArcadeTypeComponent,
		ArcadeThreeDComponent,
		ArcadeLogoComponent,
		ArcadeKnobComponent,
		ArcadeToggleComponent,
	],
	imports: [CommonModule, MatTooltipModule, RouterModule],
	exports: [
		ArcadeButtonComponent,
		ArcadeDisplayComponent,
		ArcadeThreeDComponent,
		ArcadeComponent,
		ArcadeConsoleComponent,
		ArcadeTileComponent,
		ArcadeSwitchComponent,
		ArcadeIndicatorComponent,
		ArcadeTypeComponent,
		ArcadeLogoComponent,
		ArcadeKnobComponent,
		ArcadeToggleComponent,
	],
	providers: [
		ArcadeService,
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: { position: "before" },
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ArcadeModule {}
