import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'arcade-toggle',
  template: `
    <div class="toggle-wrapper">
      <div 
        class="custom-toggle" 
        [class.active]="isToggled"
        [class.square]="shape === 'square'"
        [class.vertical]="orientation === 'vertical'"
        [class.analog]="style === 'analog'"
        [class.disabled]="disabled"
        (click)="!disabled && toggle()"
        [style.width]="orientation === 'vertical' ? (size/2.5) + 'px' : size + 'px'"
        [style.height]="orientation === 'vertical' ? size + 'px' : (size/2) + 'px'"
        [style.margin-bottom]="marginBottom + 'px'"
        [style.transform]="orientation === 'vertical' ? 'rotate(180deg)' : 'none'"
      >
        <div 
          class="toggle-handle"
          [class.square]="shape === 'square'"
          [class.analog]="style === 'analog'"
          [class.on]="isToggled"
          [style.--glow-color]="onColor"
          [style.width]="orientation === 'vertical' ? '100%' : (size/2.5) + 'px'"
          [style.height]="orientation === 'vertical' ? (size/2.5) + 'px' : (size/2) + 'px'"
          [style.transform]="getHandleTransform()"
        ></div>
        <div 
          class="toggle-track"
          [class.square]="shape === 'square'"
          [class.analog]="style === 'analog'"
        ></div>
      </div>
    </div>
  `,
  styles: [`
    :host {
      --terminal-green: rgb(0, 255, 0);
			--purple: rgb(106, 0, 255);
      --miami-pink: rgb(255, 106, 213);
    }

    .toggle-wrapper {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    .indicator-light {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: all 0.3s ease;
      box-shadow: 0 0 2px rgba(0,0,0,0.5);

      &.on {
        box-shadow: 
          0 0 5px currentColor,
          0 0 10px currentColor,
          0 0 15px currentColor;
      }
    }

    .custom-toggle {
      border-radius: 4px;
      background: rgb(20, 20, 20);
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease;
      overflow: hidden;
      border: 1px solid rgb(40, 40, 40);
      box-shadow: 
        0 1px 3px rgba(0,0,0,0.3),
        inset 0 1px 1px rgba(255,255,255,0.1);

      &.analog {
        background: linear-gradient(180deg, rgb(40,40,40), rgb(25,25,25));
        box-shadow: 
          0 2px 4px rgba(0,0,0,0.4),
          inset 0 1px 1px rgba(255,255,255,0.1);
      }

      &.square {
        border-radius: 0;
      }
    }

    .toggle-handle {
      border-radius: 3px;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 1px;
      transition: all 0.3s ease;
      z-index: 2;
      background: linear-gradient(180deg, rgb(60,60,60), rgb(45,45,45));
      border: 1px solid rgb(30,30,30);
      box-shadow: 
        0 1px 3px rgba(0,0,0,0.3),
        inset 0 1px 1px rgba(255,255,255,0.2);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 2px;
        background: var(--purple);
        transition: all 0.15s ease;
        box-shadow: 
          0 0 4px var(--purple),
          0 0 8px var(--purple);
      }

      &.on:before {
        background: var(--miami-pink);
        box-shadow: 
          0 0 4px var(--miami-pink),
          0 0 8px var(--miami-pink);
      }

      &.square {
        border-radius: 0;
      }

      &.analog {
        background: linear-gradient(180deg, rgb(80,80,80), rgb(50,50,50));
        box-shadow: 
          0 2px 4px rgba(0,0,0,0.4),
          inset 0 1px 1px rgba(255,255,255,0.2);
      }
    }

    .toggle-track {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      transition: all 0.3s ease;
      background: linear-gradient(180deg, rgb(15,15,15), rgb(25,25,25));
      overflow: hidden;

      &.square {
        border-radius: 0;
      }

      &.analog {
        background: linear-gradient(180deg, rgb(15,15,15), rgb(25,25,25));
      }
    }

    .custom-toggle:hover .toggle-handle {
      filter: brightness(1.1);
    }

    .custom-toggle:active .toggle-handle {
      filter: brightness(0.9);
    }
  `]
})
export class ArcadeToggleComponent {
  @Input() isToggled = false;
  @Input() size = 30;
  @Input() marginBottom = 8;
  @Input() onColor = 'rgb(0, 255, 0)';
  @Input() offColor = 'rgb(255, 100, 255)';
  @Input() shape: 'rounded' | 'square' = 'rounded';
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() style: 'flat' | 'analog' = 'analog';
  @Input() disabled = false;
  @Output() toggled = new EventEmitter<boolean>();

  getHandleTransform(): string {
    const distance = this.orientation === 'vertical' ? 
      this.size - (this.size/2.5) - 2 : 
      this.size - (this.size/2.5) - 2;
      
    if (this.orientation === 'vertical') {
      return this.isToggled ? 
        `translateY(${distance}px)` : 
        'translateY(0)';
    }
    return this.isToggled ? 
      `translateX(${distance}px)` : 
      'translateX(0)';
  }

  toggle() {
    if (!this.disabled) {
      this.isToggled = !this.isToggled;
      this.toggled.emit(this.isToggled);
    }
  }
} 
